import { 
    SET_PAGE, 
    TOGGLE_USER_MODAL,
    TOGGLE_PROFILE_MODAL,
    TOGGLE_AUTH_MODAL,
    SET_AUTH_MODAL,
    TOGGLE_VERIFY_SCHOOL_MODAL,
    SET_PROFILE_MODAL,
    TOGGLE_POST_MODAL,
    SET_POST_MODAL,
    TOGGLE_COMMENT_MODAL,
    SET_COMMENT_MODAL,
    TOGGLE_SHARE_MODAL,
    SET_NAV1, 
    REMOVE_NAV1, 
    SET_NAV2, 
    REMOVE_NAV2, 
    SET_NAV3, 
    REMOVE_NAV3, 
    SET_MAIN_NAV,
    TOGGLE_SIDE_NAV,
    TOGGLE_ITEM_MODAL, 
    TOGGLE_COLLECTION_MODAL, 
    TOGGLE_LOCATION_MODAL, 
    REMOVE_ITEM_MODAL, 
    REMOVE_COLLECTION_MODAL, 
    REMOVE_LOCATION_MODAL,  
    SET_SCROLL_TO_TOP,
    // Marketplace 
    SET_IS_MARKETPLACE,

    // Driver Portal
    TOGGLE_QUIT_DELIV_MODAL,
    TOGGLE_START_DELIV_MODAL,
    TOGGLE_CONFIRM_PICKUP_MODAL,
    TOGGLE_CUSTOMER_CODE_MODAL,
    SET_CAMPUS_ID,
    SET_COUNTRY_CODE,
    SET_FIREWALL
} from '../actions/types';

const initialState = {
    // Campus Logic
    campus_id: '',
    campus_loading: true,
    campus_name: '',
    campus_ext: '',
    campus_privacy: 'private',
    campus_url: '',
   // Navigation state
    nav1: '', // Navigation state for level 1
    nav2: '', // Navigation state for level 2
    nav3: '', // Navigation state for level 3
    page: '', // Current page being displayed
    main: '', // Main navigation state
    scroll_to_top: false,

    // Modal states
    userModal: false, // User modal state
    profileModal: false, // Profile modal state
    postModal: false, // Post modal state
    commentModal: false, // Comment modal state
    authModal: false, // Authentication modal state
    verifySchool_Modal: false,
    authModalType: '', // Type of authentication modal being displayed
    itemModal: false, // Item modal state
    collectionModal: false, // Collection modal state
    locationModal: false, // Location modal state

    // Other state
    sideNav: false, // State of the side navigation
    loading: true, // Loading state

    // Marketplace
    isMarketplace: false,

    // Driver Portal
    driver_quitDeliveryModal: false, // Quit Delivery modal state
    driver_startDeliveryModal: false, // Start Delivery modal state
    driver_confirmPickupModal: false, // Confirm Pickup modal state
    driver_customerCodeModal: false, // Customer Code modal state

    // Security Logic
    country_code: '',
    show_firewall: false,
    geo_loading: true
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_SCROLL_TO_TOP: {

            // Set the scroll_to_top state to the payload value
            return {
                ...state,
                scroll_to_top: payload
            };
        }
        case SET_PAGE: {

            // Set the page state to the payload value
            return {
                ...state,
                page: payload,
                loading: false
            };
        }
        case SET_IS_MARKETPLACE: 

            return {
                ...state,
                isMarketplace: payload
            }
        case TOGGLE_USER_MODAL: 

            // Toggle the userModal state between open and closed
            return {
                ...state,
                userModal: !state.userModal
            }
        case TOGGLE_PROFILE_MODAL: 

            // Toggle the profileModal state between open and closed
            return {
                ...state,
                profileModal: !state.profileModal
            }
        case TOGGLE_POST_MODAL: 

            // Toggle the postModal state between open and closed
            return {
                ...state,
                postModal: !state.postModal
            }
        case SET_POST_MODAL: 

            // Set the postModal state to the payload value
            return {
                ...state,
                postModal: payload
            }
        case TOGGLE_COMMENT_MODAL: 

             // Toggle the commentModal state between open and closed
            return {
                ...state,
                commentModal: !state.commentModal
            }
        case SET_COMMENT_MODAL: 

            // Set the commentModal state to the payload value
            return {
                ...state,
                commentModal: payload
            }
        case TOGGLE_AUTH_MODAL: 

            // Toggle the authModal state between open and closed and set the authModalType to the payload value
            return {
                ...state,
                authModal: !state.authModal,
                authModalType: payload
            }
        case SET_AUTH_MODAL: 

            // Toggle the authModal state between open and closed and set the authModalType to the payload value
            return {
                ...state,
                authModal: payload,
            }
        case TOGGLE_VERIFY_SCHOOL_MODAL: 

            // Toggle the verifySchool_Modal state between open and closed and set the authModalType to the payload value
            return {
                ...state,
                verifySchool_Modal: !state.verifySchool_Modal,
                authModalType: payload
            }
        case SET_PROFILE_MODAL: 

            // Set the profileModal state to the payload value
            return {
                ...state,
                profileModal: payload
            }
        case TOGGLE_SHARE_MODAL: 

            // Toggle the shareModal state between open and closed
            return {
                ...state,
                shareModal: !state.shareModal
            }
        case SET_MAIN_NAV: {

            // Set the main navigation state to the payload value
            return {
                ...state,
                main: payload,
                loading: false
            };
        }
        case SET_NAV1: {

            // Set the nav1 state to the payload value and reset nav2 and nav3
            return {
                ...state,
                nav1: payload,
                nav2: '',
                nav3: ''
            };
        }
        case SET_NAV2: {

            // Set the nav2 state to the payload value
            return {
                ...state,
                nav2: payload
            };
        }
        case SET_NAV3: {

            // Set the nav3 state to the payload value
            return {
                ...state,
                nav3: payload
            };
        }
        case REMOVE_NAV1: {

            // Remove the nav1 state
            return {
                ...state,
                nav3: ''
            };
        }
        case REMOVE_NAV2: {

            // Remove the nav2 state
            return {
                ...state,
                nav3: ''
            };
        }
        case REMOVE_NAV3: {

            // Remove the nav3 state
            return {
                ...state,
                nav3: ''
            };
        }
        case TOGGLE_SIDE_NAV: 

            // Toggle the sideNav state between open and closed
            return {
                ...state,
                sideNav: !state.sideNav
            }
        case TOGGLE_ITEM_MODAL:
            
            // Set the itemModal state to true
            return {
                ...state,
                itemModal: true
            }
        case TOGGLE_COLLECTION_MODAL: 

            // Set the collectionModal state to true
            return {
                ...state,
                collectionModal: true
            }
        case TOGGLE_LOCATION_MODAL: 

            // Set the locationModal state to true
            return {
                ...state,
                locationModal: !state.locationModal
            }
        case REMOVE_ITEM_MODAL: 

            // Remove the itemModal state
            return {
                ...state,
                itemModal: false
            }
        case REMOVE_COLLECTION_MODAL: 

            // Remove the collectionModal state
            return {
                ...state,
                collectionModal: false
            }
        case REMOVE_LOCATION_MODAL: 

            // Remove the locationModal state
            return {
                ...state,
                locationModal: false
            }

        // ---- DRIVER PORTAL ----
        
        case TOGGLE_QUIT_DELIV_MODAL: 

            // Toggle the driver_quitDeliveryModal state between open and closed
            return {
                ...state,
                driver_quitDeliveryModal: !state.driver_quitDeliveryModal
            }
        case TOGGLE_START_DELIV_MODAL: 

            // Toggle the driver_startDeliveryModal state between open and closed
            return {
                ...state,
                driver_startDeliveryModal: !state.driver_startDeliveryModal
            }
        case TOGGLE_CONFIRM_PICKUP_MODAL: 

            // Toggle the driver_confirmPickupModal state between open and closed
            return {
                ...state,
                driver_confirmPickupModal: !state.driver_confirmPickupModal
            }
        case TOGGLE_CUSTOMER_CODE_MODAL: 

            // Toggle the driver_customerCodeModal state between open and closed
            return {
                ...state,
                driver_customerCodeModal: !state.driver_customerCodeModal
            }
        case SET_CAMPUS_ID: 

            // Set the current Campus ID

            let campus_name;
            let campus_ext;
            let campus_privacy;
            let campus_url;

            if (payload === 'sWlhtpjZ3UFPMwFF4vJX') {  // Campus Buy Sell
                campus_name = 'Campus';
                campus_ext = 'campusbuysell.com';
                campus_privacy = 'private';
                campus_url = 'https://www.campusbuysell.com';
            }
            else if (payload === 'b7nqQGbbbzw08uh0MoPA') {  // University of Oklahoma
                campus_name = 'OU';
                campus_ext = 'ou.edu';
                campus_privacy = 'private';
                campus_url = 'https://www.oubuysell.com';
            } else if (payload === 'j4R70faL7jaXTR5Nzeex') { // University of Texas 
                campus_name = 'UT';
                campus_ext = 'utexas.edu';
                campus_privacy = 'private';
                campus_url = 'https://www.utbuysell.com';
            } 
            else if (payload === 'fNie9ly0jB0cs3tZHQaQ') { // Southern Methodsit University (SMU)
                campus_name = 'SMU';
                campus_ext = 'smu.edu';
                campus_privacy = 'private';
                campus_url = 'https://www.smubuysell.com';
            } 
            else if (payload === 'g4O5N3JOeQPiMxoSbCex') { // University of Florida (UF)
                campus_name = 'UF';
                campus_ext = 'ufl.edu';
                campus_privacy = 'private';
                campus_url = 'https://www.ufbuysell.com';
            } 
            else {
                campus_name = 'OU';
                campus_ext = 'ou.edu';
                campus_privacy = 'private';
                campus_url = 'https://www.oubuysell.com';
            }

            return {
                ...state,
                campus_id: payload,
                campus_name,
                campus_ext,
                campus_privacy,
                campus_url,
                campus_loading: false
            }
        case SET_COUNTRY_CODE: 

            // Set the current Country Code of the user IP

            return {
                ...state,
                country_code: payload,
                geo_loading: false
            }
        case SET_FIREWALL: 

            // Set the current Country Code of the user IP

            return {
                ...state,
                show_firewall: payload,
            }
        default:
            return state;
    }
}