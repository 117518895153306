import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Route, useHistory, Link } from 'react-router-dom';
import Div100vh, { use100vh } from 'react-div-100vh';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faSnapchat } from '@fortawesome/free-brands-svg-icons'
// import { faTwitter } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Firebase
import { db } from './config/firebase-config';
import { collection, getDocs, query, where, doc } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Routing
import Routes from './components/routes/Routes';

// Actions
import { setAlert } from './actions/alertActions';
import { toggleSideNav, toggleUserModal, toggleProfileModal, togglePostModal, toggleShareModal, toggleAuthModal, setProfileModal, set_scroll_to_top } from './actions/navActions';
import { createChat, getUnreadChats } from './actions/chatActions';
import { setUsers, getUserById, clearUser } from './actions/authActions';
import { clearEditorText, clearEditorFiles, clearUrls } from './actions/postActions';
import { getUnreadCount } from './actions/notificationActions';

// Components - imported
import SidebarMobile from './components/sidebar/SidebarMobile';
import Sidebar from './components/sidebar/Sidebar';
import Backdrop from './components/common/Backdrop';

// Modal
import DefaultModal from './components/modal/DefaultModal';
import Modal from './components/modal/Modal';
import AuthModal from './components/modal/AuthModal';
import VerifySchool_Modal from './components/modal/VerifySchool_Modal';
import UserModal from './components/modal/UserModal';
import BigModal from './components/modal/BigModal';
import ModalContainer from './components/modal/ModalContainer';
import CommentModal from './components/modal/CommentModal';

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import Spinner from './components/common/Spinner';
import Logo from './components/common/Logo';
import TweetBox from './components/TweetBox/TweetBox';
import DefaultAvatar from 'react-avatar';
import AuthPage_Body from './pages/auth/AuthPage_Body';

import UserTable from './components/Tables/UserTable/UserTable';

// Sliding Edit Profile Form - Modal content
import ProfileForm from './components/Profile/ProfileModal/ProfileForm';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SearchIcon from '@material-ui/icons/Search';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CreateIcon from '@material-ui/icons/Create';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddIcon from '@material-ui/icons/Add';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import InstagramIcon from '@material-ui/icons/Instagram';

const Body = ({ 
    createChat,
    setUsers,
    getUserById,
    clearUser,
    clearEditorText, 
    clearEditorFiles, 
    clearUrls,
    auth,
    setAlert,
    toggleUserModal,
    toggleProfileModal,
    toggleAuthModal,
    setProfileModal,
    set_scroll_to_top,
    togglePostModal,
    toggleShareModal,
    toggleSideNav,
    nav: { 
        sideNav,
        userModal,
        profileModal,
        postModal,
        commentModal,
        shareModal,
        authModal,
        authModalType,
        page,
        loading,
        campus_name,
        campus_loading,
        campus_url
    },
    post: {
        post,
        postUploading,
        postUploadingImgs
    },
    notification,
    chat,
}) => {

    // Window width state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Check if user is verified
    const [checkedVerification, setCheckedVerification] = useState(false);

    // SideNav and Overlay should disappear if not isMobile
    const [resetSideNav, setResetSideNav] = useState(false);

    // State to determine highlighted mobile navbar option - bottom of screen
    const [navHighlight, setNavHighlight] = useState(null);

    // Check if user is set up
    const [gotUserIsSetUp, setGotUserIsSetUp] = useState(false);

    // Show authentication alert
    const [showAuthAlert, setShowAuthAlert] = useState(true);

    // Closed state for post modal
    const [closedPostModal, setClosedPostModal] = useState(false);

    // Modal toggles
    const [discardModal, setDiscardModal] = useState(false);
    const [postUploadingModal, setPostUploadingModal] = useState(false);
    const [postUploadingImgsModal, setPostUploadingImgsModal] = useState(false);
    const [accountUpdatingModal, setAccountUpdatingModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);

    // --- Search stuff

    const [searchText, setSearchText] = useState('');

    const [searchUserList, setSearchUserList] = useState([]);
    
    const [userListOptionIndex, setUserListOptionIndex] = useState(-1);

    const [searchUserListArray, setSearchUserListArray] = useState([]);

    // --- END Search stuff

    let history = useHistory();

    // Get URL parameters
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const showProfileModal = url.searchParams.get("setUp");
    const showPostModal = url.searchParams.get("compose");

    // Algoia Search
    const searchUsersClient = algoliasearch('H8YQ3RB3ZI', 'b797e6858550b93ae143de5b4941f4e9');
    const usersIndex = searchUsersClient.initIndex('users');

    // --- Alert Badges

        // State to determine whether to display the badge or not
        const [showBadge, setShowBadge] = useState(false);

        // State to hold the value to be displayed on the badge
        const [badgeValue, setBadgeValue] = useState(0);

        const [chatBadgeValue, setChatBadgeValue] = useState(0);

        // State to track if the badge value has been lowered
        const [loweredBadge, setLoweredBadge] = useState(false);

    // --- END Alert Badges

    // Check if the user has unread notifications or messages
    useEffect(() => {
        if(auth.user) {

                // If there are unread notifications
                if(!notification.read) {

                    // Fetch the count of unread notifications
                    getUnreadCount(auth.user._id);
                } 

                // Fetch the count of unread messages
                getUnreadChats();

        }
    }, [notification.notifications, notification.read, auth.user, page]);

    // Lower the badge value if the chat is opened
    useEffect(() => {
        if(chat.chat && !loweredBadge) {
            
            // Lower the badge value by 1
            lowerBadgeValue();
        }

        if(page === 'notifications') {
            setBadgeValue(0);
        }
        
    }, [chat.chat, page])

    // Update the badge value based on the unread notifications or messages count
    useEffect(() => {

        // Update the badge value with the count of unread notifications
        setBadgeValue(notification.num_unread);

        // Update the badge value with the count of unread messages
        setChatBadgeValue(chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])


    // Lower the badge value by 1
    const lowerBadgeValue = () => {
        console.log('LOWER BADGE #');

        // Decrease the badge value by 1
        const newValue = badgeValue - 1;
        const newChatValue = chatBadgeValue - 1;
        
        // Update the badge value state
        setBadgeValue(newValue);
        setChatBadgeValue(newChatValue);

        // Set the flag to indicate that the badge value has been lowered
        setLoweredBadge(true);
    }

    // Listen for window resize
    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // SideNav and Overlay should disappear if not isMobile
    useEffect(() => {
        if(resetSideNav) {
            toggleSideNav();
            setResetSideNav(false);
        }
    }, [resetSideNav]);

    // --- Set the navigation highlight based on the current page
    useEffect(() => {
        if(page === 'home') {
            setNavHighlight('home');
        }
        if(page === 'search') {
            setNavHighlight('search');
        }
        if(page === 'search schools') {
            setNavHighlight('search schools');
        }
        if(page === 'notifications') {
            setNavHighlight('notifications');
        }

        if(page === 'messages') {
            setNavHighlight('messages');
        }

        if(page === 'saved') {
            setNavHighlight('saved');
        }

        if(page === 'profile') {
            setNavHighlight('profile');
        }

        if(page === 'settings') {
            setNavHighlight('settings');
        }

    }, [page]);

    
    // Update window width on resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Show the Edit Profile modal if showProfileModal is true
    useEffect(() => {
        if(auth.user && showProfileModal === 'true') {
            setProfileModal(true);
        }

        // if(auth.user && showPostModal === 'true') {
        //     togglePostModal(true);
        // }

    }, [page, auth.user])

    useEffect(() => {

        if(page === 'messages') {
            if(searchText.length === 0) {
                clearUser();
    
                setSearchUserList([]);
                
                setSearchUserListArray([]);
            } else {
                clearUser();
            }
        }

    }, [searchText])

    useEffect(() => {

        if(auth.modalUser && searchText.length > 0) {
            toggleMessageModal()
        }

    }, [auth.modalUser])

    // Add Font Awesome icon from the FA library
    library.add(fab, faTwitter)


    const toggleDiscardModal = () => {
        setDiscardModal(!discardModal);
    }

    const togglePostUploadingModal = () => {
        setPostUploadingModal(!postUploadingModal);
    }

    const togglePostUploadingImgsModal = () => {
        setPostUploadingImgsModal(!postUploadingImgsModal);
    }

    const toggleAccountUpdatingModal = () => {
        setAccountUpdatingModal(!accountUpdatingModal);
    }

    // Modal toggle logic

    const handleDiscard = () => {
        setDiscardModal(false);
        togglePostModal();
        
        clearEditorFiles();
        clearUrls();
        clearEditorText();
    }

    const handleUserClick = (hit) => {
        if(auth.modalUser) {
            clearUser();
        } else {
            getUserById(hit.objectID);
        }
    }

    // Function to handle input change in 'New Message' user search modal
    const onChange = e => setSearchText(e.target.value);

    const onDownArrow = () => {

        // TODO: Detect arrow keys when the input is not in focus, 
        // currently if user clicks the cancel button the arrows dont work unless the user clicks the input again

        console.log('SEARCH ARRAY')
        console.log(searchUserListArray.length)

        console.log('SEARCH INDEX')
        console.log(userListOptionIndex + 1)

        console.log('REQ LENGTH INDEX')
        console.log(searchUserList.length)


        let nextIndex = userListOptionIndex + 1;

        if(nextIndex > searchUserListArray.length - 1) {
            nextIndex = 0;
        }

        setUserListOptionIndex(nextIndex);
    }

    const onUpArrow = () => {

        let nextIndex = userListOptionIndex - 1;

        if(nextIndex === -1 ) {
            nextIndex = searchUserListArray.length - 1;
        }

        setUserListOptionIndex(nextIndex);
    }

    const handleUserModal = () => {
        if(userModal) {
            toggleUserModal();
            clearUser();
            setUserListOptionIndex(-1); // Reset search dropdown index
            setSearchUserList([]); // Reset user search
            setSearchUserListArray([]); // Reset user search index array
            setSearchText(''); // Reset searchText
        } else {
            toggleUserModal();
        }
    }

    const toggleMessageModal = () => {

        if(auth.modalUser) {
            setMessageModal(!messageModal);
        }
    }

    // User clicked the 'Cancel' button in the message modal
    const toggleTodo = () => {
        toggleMessageModal();
        clearUser();
    }

    // Handle chat creation
    const createNewChat = async () => {

        // Query existing chats between users
        const chatsCollectionRef = collection(db, "chats");

        // Check if chat with 2 users already exists by chat ID

        console.log('CHECK USER CHAT HERE')
        
        const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", auth.user._id), where("to_user._id", "==", auth.modalUser._id));
        const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", auth.user._id), where("from_user._id", "==", auth.modalUser._id));

        const chatsSnapshot = await getDocs(chatsQuery);
        const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        const otherChatsSnapshot = await getDocs(otherChatsQuery);
        const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Check if chats were retrieved in console (for testing)
        console.log('CHECKING CHATS DATA');
        console.log(chats);
        
        console.log('CHECKING OTHER CHATS DATA');
        console.log(otherChats);

        console.log('CHECK USER CHAT HERE PART 2')

        // If array length > 0 then there is already existing chat & redirect instead
      
        if(chats.length > 0) {
            history.push(`/messages/${chats[0]._id}`);
        } else if (otherChats.length > 0) {
            history.push(`/messages/${otherChats[0]._id}`);
        } else {
            createChat(auth.modalUser, auth.user, history);
        }

        handleUserModal();
        setMessageModal(!messageModal);
        
        console.log('SHOULD BE DONE CREATING');
    }

    // Redirect to login page
    const loginLink = () => {
        history.push('/login')
    }

    // Redirect to register page
    const registerLink = () => {
        history.push('/register')
    }

    // Handle copy action
    const handleCopy = () => {

        /* Get the text field */
        var copyLink = document.getElementById("linkToCopy");

        /* Select the text field */
        copyLink.select();
        copyLink.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyLink.value);

        /*Close Modal & show Alert */
        toggleShareModal();
        setAlert('Copied to clipboard', 'okay');
    }

    const handleUserSearch = async (queryText) => {

        if(searchText.length - 1 > 0) {
            setUserListOptionIndex(-1); // Reset search dropdown index

            try {

                if(queryText === '') return;

                const results = await usersIndex.search(queryText);

                console.log('SEARCH RESULT');
                console.log(results);

                setSearchUserList(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

                setSearchUserListArray([...results.hits.map((doc) => ({...doc, _id: doc.objectID}))]);

            } catch (error) {
                console.log(error);
                setSearchUserList([]);
            }
        }
    }

    // Message Modal -- Submit and create new chat on 'Enter'
    const handleSubmit = (queryText) => {
        if(userListOptionIndex > -1) {
            handleUserClick(searchUserListArray[userListOptionIndex])

            console.log('SEARCH THIS WORD')
            console.log(searchUserListArray[userListOptionIndex]);
        } else {
            if(queryText === '') return;

            window.alert('Please select a user!');
        }
    }

    // ----- BottomNav Actions Logic -------
    
    // Handle bottomNav create post btn click
    const handleCreatePost = () => {
        if(auth.user) {
            
            window.location = `/create/post?goBack=${page}`;

        } else {

            // Toggles the authentication modal for liking
            toggleAuthModal('post');
        }
    }

    const scrollToTop = () => {
        set_scroll_to_top(true)

        console.log('SCROLL TO TOP')
    };

    // ----- END: BottomNav Actions Logic -------


    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    const pageHeight = use100vh();
    // const bodyHeight = pageHeight ? pageHeight - 56 : '95vh';

    if(isMobile && profileModal && !loading && page !== 'set-up') {

        // If the user is on a mobile device, the profile modal is open,
        // the page is not in the loading state, and the page is not 'set-up',
        // redirect the user to the profile set-up page with a query parameter for navigation.
        history.push({
            pathname: '/profile/set-up',
            search: `?goBack=${page}`,
        })
    }

    // if(postModal && page === 'home' && !closedPostModal) {
    //     togglePostModal();
    //     setClosedPostModal(true);
    // }

    if(isMobile && postModal && !loading && page !== 'create') {
        
        // If the user is on a mobile device, the post modal is open,
        // the page is not in the loading state, and the page is not 'create',
        // redirect the user to the Create Post page with a query parameter for navigation.
        history.push({
            pathname: '/create/post',
            search: `?goBack=${page}`,
        })
    }

    if(isMobile && commentModal && !loading && page !== 'create') {
        
        // If the user is on a mobile device, the post modal is open,
        // the page is not in the loading state, and the page is not 'create',
        // redirect the user to the Create Post page with a query parameter for navigation.
        history.push({
            pathname: `/create/comment/${post._id}`,
            search: `?goBack=${page}`,
        })
    }

    if(isMobile && userModal && !loading && page !== 'create') {
        
        // If the user is on a mobile device, the post modal is open,
        // the page is not in the loading state, and the page is not 'create',
        // redirect the user to the Create Post page with a query parameter for navigation.
        history.push({
            pathname: `/create/message`,
            search: `?goBack=${page}`,
        })
    }

    // Check if user is verified. If no redirect to verify page
    if(auth.user && !auth.user?.verified && !checkedVerification) {
        window.location.href = '/verify';

        setCheckedVerification(true);
    }

    let backdrop;

    // Create a backdrop component if the sideNav is open
    if (sideNav) {
        backdrop = <Backdrop click={toggleSideNav} />;
    }

    // SideNav and Overlay should disappear if not isMobile
    if (sideNav && !isMobile && !resetSideNav) {

        // If the sideNav is open, the device is not a mobile device,
        // and the resetSideNav state is not set, set the resetSideNav state to true
        // to show the sideNav and overlay again.
        setResetSideNav(true);
    }

    if (sideNav && isMobile && resetSideNav) {

        // If the sideNav is open, the device is a mobile device,
        // and the resetSideNav state is set, set the resetSideNav state to false
        // to hide the sideNav and overlay.
        setResetSideNav(false);
    }

    if (showProfileModal === 'true' && auth.user && auth.user.profile_set_up && page === 'profile') {

        // If the showProfileModal query parameter is 'true', the user is authenticated,
        // their profile is set up, and page is set to 'profile' 
        // (meaning they just completed their profile),
        // --> redirect the user to the profile page.
        window.location.href = "/profile";
    }

    let user_list = [];

    if(searchUserList.length > 0) {

        // Iterate through the posts array and render each post component   
        for (let i = 0; i < searchUserList.length; i++) {
            const hit = searchUserList[i];
            
            if (hit) {
                user_list.push(
                    <div key={hit.objectID} onClick={() => handleUserClick(hit)} className={userListOptionIndex === i || (auth.modalUser && (hit.objectID === auth.modalUser._id)) ? "userTable__block active" : "userTable__block"}>
                        
                        {/* User Avatar - left */}
                        <div className="userTable__avatar">
                            {hit.img ? (
                                <Avatar src={hit.img} />
                            ) : (
                                <DefaultAvatar name={`${hit.first_name} ${hit.last_name}`} />
                            )}
                        </div>

                        {/* Display the user's name & username */}
                        <div className="userTable__info">
                            <h3>
                                {hit.first_name}{' '}{hit.last_name}
                                <span className="userTable__infoSpecial">
                                    <VerifiedUserIcon className="userTable__badge"></VerifiedUserIcon>
                                </span>
                            </h3>
                            <p>@{hit.username}</p>
                        </div>

                        {/* Show if User Block has been clicked */}
                        <div className="userTable__active">

                            {/* Check if the current user is the same as the selected user */}
                            {auth.modalUser && (hit.objectID === auth.modalUser._id) && <CheckIcon />}
                        </div>
                    </div>
                );
            }
        }
    }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(auth.user && auth.user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <Fragment>
            
            {/* Main App */}
            <div className={sideNav ? "app open": "app"} style={{ height: pageHeight }}>

                {/* Sidebar */}
                {!isMobile ? <Sidebar isMobile={isMobile} isTablet={isTablet} /> : <SidebarMobile slideMenu={sideNav} handleSlideMenu={toggleSideNav} /> }

                {backdrop}

                {/* Routes => Different Pages */}
                <Route component={Routes} />

            </div>
            
            {/* Mobile Navbar found at the bottom of screen */}
            {!campus_loading && (
                <Fragment>
                    {campus_name === 'Campus' ? (
                        <div className={`mobile-navbar ${!auth.loading && !sideNav && (page !== 'chat' && page !== 'checkout' && page !== 'offer detail') ? "Show" : ""} ${page === "product detail" ? "stacked" : ''}`}>

                            <div className='mobile-navbar-inner'>
        
                                <div onClick={() => window.location = '/'} className={`mobile-navbar-inner-btn ${navHighlight === "grocery" ? 'active' : ''}`}>                    
                                    {navHighlight === "home" ? <HomeIcon /> : <HomeOutlinedIcon />}
                                </div>
        
                                <div onClick={() => window.location = '/search_schools'} className={`mobile-navbar-inner-btn ${navHighlight === "search schools" ? 'active' : ''}`}>
                                    <SearchOutlinedIcon />
                                </div>
                                
        
                                <div onClick={() => window.location = '/settings'} className={`mobile-navbar-inner-btn ${navHighlight === "settings" ? 'active' : ''}`}>
                                    {!auth.loading && !auth.isAuthenticated ? (
                                        <div className={`post__avatar layoutHeader ${navHighlight === "settings" ? 'active' : ''}`} style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px', position: 'relative'}}>
                                            <Avatar />
                                        </div>
                                    ) : (
                        
                                        <div className={`post__avatar layoutHeader ${navHighlight === "settings" ? 'active' : ''}`} style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px', position: 'relative'}}>
        
                                            {auth.user && auth.user.img ? (
                                                <Avatar src={optimized_profile_img_path} />
                                            ) : (
                                                <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                            )}
                                        </div>
                                    )}
        
        
                                </div>
        
                            </div>
                        </div>
                    ) : (
                        <div className={`mobile-navbar ${isMobile && !auth.loading && !sideNav && (page !== 'chat') ? "Show" : ""}`}>

                            <div className='mobile-navbar-inner'>
                                {/* Mobile Nav Bar - fixed to bottom of screen */}
                                <div onClick={() => navHighlight === "home" ? scrollToTop() : window.location.href = '/home'} className={navHighlight === "home" ? 'active' : ''}>
                                    {navHighlight === "home" ? <HomeIcon /> : <HomeOutlinedIcon />}
                                </div>

                                <Link className={navHighlight === "search" ? 'active' : ''} to='/search'>
                                    <SearchOutlinedIcon />
                                </Link>

                                <div onClick={handleCreatePost} className='add-navOption'>
                                    <AddIcon />
                                </div>

                                <Link style={{position:'relative'}} className={navHighlight === "messages" ? 'active' : ''} to='/messages'>
                                    {navHighlight === "messages" ? <MailIcon /> : <MailOutlineIcon />}
                                    
                                    {chatBadgeValue > 0 ? (
                                        <span className="notification-badge badge_for_chat">
                                            {chatBadgeValue}
                                        </span>
                                    ) : null}
                                </Link>

                                <Link style={{position:'relative'}} className={navHighlight === "notifications" ? 'active' : ''} to='/notifications'>
                                    {navHighlight === "notifications" ? <NotificationsIcon /> : <NotificationsNoneIcon />}

                                    {badgeValue > 0 ? (
                                        <span className="notification-badge badge_for_notifs">
                                            {badgeValue}
                                        </span>
                                    ) : null}
                                </Link>
                            </div>
                    </div>
                    )}
                </Fragment>
            )}


            {/* Share Website Modal */}
            <ModalContainer show={shareModal} onClose={toggleShareModal}>

                {/* Share Website Modal Content */}
                <CommentModal shareGroup>
                    <div className="closeBtn__container bothSides modalClose">

                        {/* Submit Button */}
                        <div style={{fontSize: '16px', margin:'10px 0', display: 'flex', alignItems:  'center', padding: '7px 16px', fontWeight: '600', textAlign: 'center'}}>
                            Share
                        </div>

                        {/* Close Button */}
                        <button onClick={toggleShareModal} className="secondaryBtn">
                            <CloseOutlinedIcon /> Close
                        </button>
                    </div>
                    <div style={{position:'relative', width:'100%', display:'flex', flexDirection:'row', flex:'1', flexBasis:'0.000000001px'}}>
                        <div style={{overflow:'hidden', whiteSpace:'nowrap'}}>

                            {/* Share via Email */}
                            <a target="_blank" href="mailto:support@campusbuysell.com">
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#f9f9f9', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <MailIcon style={{color:'#333', width:'35px', height:'35px'}} />
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Email
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/* Share via Twitter */}
                            {/* <a target="_blank" href="https://twitter.com/OUBuySell">
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#1DA1F2', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <FontAwesomeIcon icon={faTwitter} style={{color:'#fff', width:'35px', height:'35px'}} />
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Twitter
                                        </div>
                                    </div>
                                </div>
                            </a> */}

                            {/* Share via Instagram */}
                            <a target="_blank" href={`https://www.instagram.com/${campus_name}BuySell/?hl=en`}>
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#2BB741', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <InstagramIcon style={{color:'#fff', width:'35px', height:'35px'}} />
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Instagram
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/* Share via Snapchat */}
                            {/* <a target="_blank" href="https://www.snapchat.com/add/OUBuySell?sender_web_id=97d31af2-e177-4aaf-b31a-5ab768c332ad&device_type=desktop&is_copy_url=true">
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#FFFC00', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <FontAwesomeIcon icon={faSnapchat} style={{color:'#333', width:'35px', height:'35px'}} />
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Sanpchat
                                        </div>
                                    </div>
                                </div>
                            </a> */}
                        </div>
                    </div>

                    {/* Share via URL link */}
                    <div style={{marginTop:'8px', marginBottom:'24px', display:'inline-block', height:'42px', width:' 100%'}}>
                        <div onClick={handleCopy} style={{height:'100%', cursor:'pointer', justifyContent:'space-between', border:'1px solid rgba(0, 0, 0, 0.1)', backgroundColor:'#f9f9f9', borderRadius:'2px', display:'flex',alignItems:'center', paddingRight:'16px'}}>
                            <input 
                                readOnly
                                type="text"
                                placeholder={campus_url}
                                name="linkToCopy"
                                id="linkToCopy"
                                value={campus_url}
                                style={{width:'100%', cursor:'copy', marginLeft:'16px', border:'none', outline:'none', whiteSpace:'nowrap', backgroundColor:'rgba(0,0,0,0)', color:'#030303', fontSize:'14px'}} 
                            />
                            <div style={{padding:'0 8px', color:'#065fd4', borderRadius:'2px 3px', margin:'0 0 0', textTransform:'uppercase', fontSize:'14px', fontWeight:'500', letterSpacing:'0.5px'}}>
                                <a href="#" style={{textDecoration:'none', color:'#065fd4', fontWeight:'500'}}>
                                    Copy
                                </a>
                            </div>
                        </div>
                    </div>
                </CommentModal>
            </ModalContainer>

            {/* Authentication Modal - if not logged in && triggered an Action */}
            <AuthModal />

            <VerifySchool_Modal />

            {/* Sliding Edit Profile Modal */}
            <ModalContainer show={profileModal} onClose={toggleProfileModal}>
                <BigModal>
                    <ProfileForm />
                </BigModal>
            </ModalContainer>

            {/* Create Post Modal */}
            <ModalContainer show={postModal} onClose={toggleDiscardModal}>
                <CommentModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start modalClose">
                        <button onClick={toggleDiscardModal} className="secondaryBtn">
                            <CloseOutlinedIcon /> Close
                        </button>
                    </div>

                    {/* Tweet Box */}
                    <TweetBox modal compose />
                </CommentModal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={discardModal} onClose={toggleDiscardModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Discard Post?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Are you sure? Your draft will not be saved.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={handleDiscard}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Discard
                    </Button>
                    <Button 
                        onClick={toggleDiscardModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Keep Writing
                    </Button>
                </Modal>
            </ModalContainer>

            {/* Message User Modal */}
            <ModalContainer show={userModal} onClose={handleUserModal}>
                <UserModal>
                    {/* Modal Header */}
                    <div style={{display:'flex', justifyContent:'space-between'}}>

                            {/* Close Button */}
                            <button onClick={handleUserModal} className="secondaryBtn close">
                                <CloseIcon /> 
                                <span>Close</span>
                            </button>

                        {/* Modal Title */}
                        <h2>New Message</h2>

                        {/* Submit Button */}
                        <div>
                            <Button 
                                onClick={toggleMessageModal} 
                                className={`tweetBox__tweetButton ${!auth.modalUser ? 'disabled' : ''}`}
                                disabled={!auth.modalUser}
                            >
                                Next
                            </Button>
                        </div>
                    </div>

                    {/* User Search Bar */}
                    <div className="modal__search">
                        {/* <SearchIcon />
                        <SearchBox /> */}
                        {/* <input placeholder="Search" className="input" type="text" /> */}

                        <SearchIcon />
                        <input 
                            id="search-input" 
                            placeholder="Search OU Buy / Sell" 
                            type="text" 
                            autocomplete="off" 
                            value={searchText}
                            onChange={e => onChange(e)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter') {
                                    handleSubmit(searchText);
                                } else if(e.keyCode == '40') { // down arrow
                                    onDownArrow();
                                } else if(e.keyCode == '38') { // up arrow
                                    onUpArrow();
                                } else {
                                    handleUserSearch(searchText);
                                }
                            }}
                        />
                    </div>
                    
                    {/* User Search Results */}
                    <div className="modal-table-list-container modular">
                        {user_list}

                        {!user_list?.length > 0 && ( 

                            <div className='menu-title' style={{display: 'flex', width:'100%', color:'rgb(83, 100, 113)', fontSize:'15px', cursor:'default', justifyContent:'center', alignItems: 'center', paddingTop: '12px'}}>Try searching someone's name . . .</div>
                        )}
                    </div>
                </UserModal>
            </ModalContainer>

            {/* Create Message confirmation Modal */}  
            <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Start Conversation?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Start a private conversation with <b style={{color: '#333'}}>{auth.modalUser ? `${auth.modalUser.first_name} ${auth.modalUser.last_name && auth.modalUser.last_name}` : 'this person'}</b>.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={createNewChat}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Start Conversation
                    </Button>
                    <Button 
                        onClick={toggleTodo}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={postUploading} onClose={togglePostUploadingModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Uploading Post...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Patience is a virtue.</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={postUploadingImgs} onClose={togglePostUploadingImgsModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Uploading Photos...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Patience is a virtue.</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

            <ModalContainer show={auth.profile_update_loading} onClose={toggleAccountUpdatingModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Updating account data...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This can take up to 30 seconds.</p>
                    <p>DO NOT re-load the page!</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

        </Fragment>
    )
}



Body.propTypes = {
    // Props for navigation
    nav: PropTypes.object.isRequired,
    // Props for authentication
    auth: PropTypes.object.isRequired,
    // Props for the post state
    post: PropTypes.object.isRequired,
    // Props for the notification state
    notification: PropTypes.object.isRequired,
    // Props for the chat state,
    chat: PropTypes.object.isRequired,
    // Function to set an alert
    setAlert: PropTypes.func.isRequired,
    // Function to toggle the side navigation
    toggleSideNav: PropTypes.func.isRequired,
    // Function to toggle the user modal
    toggleUserModal: PropTypes.func.isRequired,
    // Function to toggle the profile modal
    toggleProfileModal: PropTypes.func.isRequired,
    // Function to toggle the post modal
    togglePostModal: PropTypes.func.isRequired,
    // Function to toggle the share modal
    toggleShareModal: PropTypes.func.isRequired,
    // Function to toggle the authentication modal
    toggleAuthModal: PropTypes.func.isRequired,
    // Function to set the profile modal
    setProfileModal: PropTypes.func.isRequired,
    set_scroll_to_top: PropTypes.func.isRequired,
    // Function to create a chat
    createChat: PropTypes.func.isRequired,
    // Function to set users
    setUsers: PropTypes.func.isRequired,
    // Function to get a user by ID
    getUserById: PropTypes.func.isRequired,
    // Function to clear the current modalUser
    clearUser: PropTypes.func.isRequired,
    // Function to clear the editor text
    clearEditorText: PropTypes.func.isRequired, 
    // Function to clear the editor files
    clearEditorFiles: PropTypes.func.isRequired,
    // Function to clear URLs
    clearUrls: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    // Mapping the navigation state
    nav: state.nav,
    // Mapping the authentication state
    auth: state.auth,
    // Mapping the post state
    post: state.post,
    // Mapping the notification state for the alert badges
    notification: state.notification,
    // Mapping the chat state for the alert badge
    chat: state.chat,
})

export default connect(mapStateToProps, { 
    // Connecting actions to the component
    setAlert,
    toggleSideNav, 
    toggleUserModal, 
    toggleProfileModal, 
    togglePostModal,
    toggleShareModal,
    toggleAuthModal, 
    setProfileModal,
    createChat, 
    setUsers,
    getUserById,
    clearUser,
    clearEditorText, 
    clearEditorFiles, 
    clearUrls,
    set_scroll_to_top
})(Body);
